@media all and (max-width: 800px) {
  #toolbar,
  #admin-menu {
    display: none;
  }
  html body.toolbar,
  html body.admin-menu {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
}
/*# sourceMappingURL=greenpan15.hacks.css.map */